<template>
  <van-popup
    v-model:show="show"
    position="right"
    :style="{ height: '100%', width: '100%' }"
  >
    <van-search
      v-model="searchValue"
      :show-action="show"
      :placeholder="placeholder"
      @search="onSearch"
      @cancel="onCancel"
      shape="round"
      clear-icon
      class="search-top"
    >
      <template #right-icon>
        <van-icon name="search" color="#5E7DFF" size="24px" @click="onSearch" />
      </template>
      <template #left-icon> </template>
    </van-search>
    <div class="search-list">
      <van-cell-group style="padding-top: 55px" v-if="list.length">
        <van-cell
          :title="item[title]"
          v-for="(item, index) in list"
          :key="index"
          @click="onSelect(item)"
        />
      </van-cell-group>
      <van-empty
        v-else
        image="search"
        :description="searchValue ? '没有搜到医院' : ''"
      />
    </div>
  </van-popup>
</template>

<script>
import { ref } from 'vue';
import { Popup, Search, Cell, CellGroup, Icon, Empty } from 'vant';

export default {
  name: 'SearchDrawer',
  inheritAttrs: false,
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String, // cell组件展示的title
      default: '',
      required: true,
    },
    // {key:是}
    keyValue: {
      type: Object, // 显示组件的title
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: '请输入关键词',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Popup.name]: Popup,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
  },
  setup(props, { emit }) {
    const show = ref(props.isShow);
    const searchValue = ref('');
    // 搜索
    const onSearch = function () {
      emit('searchClick', searchValue.value);
    };

    // 取消
    const onCancel = function () {
      show.value = false;
    };

    // 打开抽屉
    const openDrawer = function () {
      show.value = true;
    };

    const onSelect = function (item) {
      Object.keys(props.keyValue).map((v) => {
        emit(`update:${v}`, item[props.keyValue[v]]);
      });
      show.value = false;
    };
    return {
      searchValue,
      show,
      onSearch,
      onSelect,
      onCancel,
      openDrawer,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding-right: 15px;
}
.search-list {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  bottom: 0;
}
</style>>
