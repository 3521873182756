import request from '@/utils/request';


/**
 * @description
 * @param {file} params
 * @returns
 */
export function uploadFile(data) {
  return request({
    url: '/uploadFile',
    method: 'post',
    data,
  });
}
