<template>
  <div class="container">
    <div class="page-title">
      <div class="title">医生认证</div>
      <div class="tag" style="color: #ff5e5e">会议快速注册，红色项目必填</div>
    </div>
    <van-form @submit="handleSubmit" label-width="78px" scroll-to-error>
      <div class="box">
        <van-cell-group>
          <van-field
            v-model="state.name"
            label-class="label-bold"
            name="name"
            label="真实姓名"
            placeholder="请填写真实姓名"
            :rules="[
              {
                validator: validatorName,
                message: '请填写真实姓名',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #label>
              <span style="color: #ff5e5e">真实姓名</span>
            </template>
          </van-field>
          <van-field
            v-model="state.region"
            is-link
            label-class="label-bold"
            readonly
            name="regionPicker"
            label="地区"
            placeholder="请选择医院所在地区"
            @click="show.showRegionPicker = true"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择医院所在地区',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #label>
              <span style="color: #ff5e5e">地区</span>
            </template>
          </van-field>
          <van-popup v-model:show="show.showRegionPicker" position="bottom">
            <van-area
              :area-list="area"
              @confirm="handlePickerConfirm($event, 'area')"
              @cancel="show.showRegionPicker = false"
            />
          </van-popup>
          <van-field
            v-model="state.hospitalName"
            label-class="label-bold"
            name="hospitalPicker"
            label="医院"
            placeholder="请输入医院"
            :rules="[
              {
                validator: validatorBlank,
                message: '请输入医院',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #button>
              <van-button size="mini" @click="openSearchDrawer" type="primary">选择医院</van-button>
            </template>
            <template #label>
              <span style="color: #ff5e5e">医院</span>
            </template>
          </van-field>
          <van-field
            is-link
            readonly
            @click="show.showDepartmentPicker = true"
            v-model="state.departmentName"
            name="departmentId"
            label="科室"
            label-class="label-bold"
            placeholder="请选择科室名称"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择科室名称',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #label>
              <span style="color: #ff5e5e">科室</span>
            </template>
          </van-field>
          <van-popup v-model:show="show.showDepartmentPicker" position="bottom">
            <van-picker
              :columns="departmentList"
              @confirm="handlePickerConfirm($event, 'department')"
              :columns-field-names="{ text: 'title' }"
              @cancel="show.showDepartmentPicker = false"
            />
          </van-popup>
          <van-field
            v-model="state.title"
            name="title"
            label="职称"
            is-link
            readonly
            label-class="label-bold"
            placeholder="请选择职称"
            @click="show.showTitlePicker = true"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择职称',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #label>
              <span style="color: #ff5e5e">职称</span>
            </template>
          </van-field>
          <van-popup v-model:show="show.showTitlePicker" position="bottom">
            <van-picker
              :columns="titleColums"
              @cancel="show.showTitlePicker = false"
              @confirm="handlePickerConfirm($event, 'title')"
            />
          </van-popup>
          <van-field
            is-link
            readonly
            name="calendar"
            label-class="label-bold"
            label="从业时间"
            v-model="state.startWorkTime"
            placeholder="请选择加入从业时间"
            @click="show.showCalendar = true"
            :rules="[
              {
                validator: validatorBlank,
                message: '请选择从业时间',
                trigger: 'onSubmit',
              },
            ]"
          >
            <template #label>
              <span style="color: #ff5e5e">从业时间</span>
            </template>
          </van-field>
          <van-popup v-model:show="show.showCalendar" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择从业时间"
              :min-date="minDate"
              :formatter="dateformatter"
              v-model="currentDate"
              @confirm="handlePickerConfirm($event, 'startWorkTime')"
              @cancel="show.showCalendar = false"
            />
          </van-popup>
        </van-cell-group>
      </div>
      <div class="box">
        <div class="box-title">
          <div class="title label-bold" style="color: #ff5e5e">上传身份证件</div>
          <div class="tag">请上传身份证正反面照片</div>
        </div>
        <div class="box-upload box-upload-hidden">
          <div class="box-upload-container">
            <van-uploader v-model="idPhotoZList" image-fit="contain" class="uploader-img" :max-count="1">
              <div class="phpto-box">
                <img class="img" src="@/assets/idPhotoZ.png" />
              </div>
            </van-uploader>
          </div>
          <div class="box-upload-container">
            <van-uploader :max-count="1" class="uploader-img" v-model="idPhotoFList" image-fit="contain">
              <div class="phpto-box">
                <img class="img" src="@/assets/idPhotoF.png" />
              </div>
            </van-uploader>
          </div>
        </div>
        <van-field
          v-model="state.idNumber"
          name="title"
          label="身份证号"
          label-class="label-bold"
          placeholder="请填写身份证号"
          :rules="[
            {
              validator: validatorIdCard,
              message: '请填写正确身份证号',
              trigger: 'onSubmit',
            },
          ]"
        >
          <template #label>
            <span style="color: #ff5e5e">身份证号</span>
          </template>
        </van-field>
        <div class="box-title">
          <div class="title label-bold">上传专业技术资格证书（职称证）</div>
          <div class="tag">若无证书，可用工作胸牌替代</div>
        </div>
        <div class="box-upload">
          <div class="box-upload-container">
            <van-uploader
              class="uploader-img img-many"
              v-model="gradeCertificatePhotoList"
              image-fit="contain"
              multiple
            >
              <template #default>
                <!-- <template>
                  <div
                    class="phpto-box"
                    v-for="(item, index) in gradeCertificatePhotoList"
                    :key="index"
                  >
                    <van-image :src="item.content" lazy-load fit="scale-down" />
                  </div>
                </template> -->
                <div class="phpto-box">
                  <img class="img" src="@/assets/camara.png" />
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <van-field
          v-model="state.gradeCertificateNumber"
          name="gradeCertificateNumber"
          label="证书编号"
          label-class="label-bold"
          placeholder="请填写证书编号"
        />
        <van-field
          v-model="state.gradeCertificateIssueTime"
          name="gradeCertificateIssueTime"
          is-link
          readonly
          label-class="label-bold"
          label="签发日期"
          placeholder="请选择签发日期"
          @click="show.showGradeCertificateIssueTime = true"
        />
        <van-popup v-model:show="show.showGradeCertificateIssueTime" position="bottom">
          <van-datetime-picker
            type="date"
            title="签发日期"
            :min-date="minDate"
            v-model="currentDate"
            :formatter="dateformatter"
            @confirm="handlePickerConfirm($event, 'gradeCertificateIssueTime')"
            @cancel="show.showGradeCertificateIssueTime = false"
          />
        </van-popup>

        <div class="box-title">
          <div class="title label-bold">上传医师执业证书</div>
          <div class="tag">包括个人信息页、医院名称的记录页</div>
        </div>
        <div class="box-upload">
          <div class="box-upload-container">
            <van-uploader
              class="uploader-img img-many"
              v-model="certificateOfPracticePhotoList"
              image-fit="contain"
              multiple
            >
              <template #default>
                <div class="phpto-box">
                  <img class="img" src="@/assets/camara.png" />
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <van-field
          v-model="state.certificateOfPracticeNumber"
          name="certificateOfPracticeNumber"
          label="证书编号"
          label-class="label-bold"
          placeholder="请填写证书编号"
        />
        <van-field
          v-model="state.certificateOfPracticePhotoIssueTime"
          name="certificateOfPracticePhotoIssueTime"
          is-link
          readonly
          label-class="label-bold"
          label="签发日期"
          placeholder="请选择签发日期"
          @click="show.showCertificateOfPracticePhotoIssueTime = true"
        />
        <van-popup v-model:show="show.showCertificateOfPracticePhotoIssueTime" position="bottom">
          <van-datetime-picker
            type="date"
            title="签发日期"
            :min-date="minDate"
            v-model="currentDate"
            :formatter="dateformatter"
            @confirm="handlePickerConfirm($event, 'certificateOfPracticePhotoIssueTime')"
            @cancel="show.showCertificateOfPracticePhotoIssueTime = false"
          />
        </van-popup>

        <div class="box-title">
          <div class="title label-bold">上传医师资格证书</div>
          <div class="tag">包括个人信息页、变更页</div>
        </div>
        <div class="box-upload">
          <div class="box-upload-container">
            <van-uploader
              class="uploader-img img-many"
              v-model="doctorsCertificatePhotoList"
              image-fit="contain"
              multiple
            >
              <template #default>
                <div class="phpto-box">
                  <img class="img" src="@/assets/camara.png" />
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <van-field
          v-model="state.doctorsCertificateNumber"
          name="doctorsCertificateNumber"
          label="证书编号"
          label-class="label-bold"
          placeholder="请填写证书编号"
        />
        <van-field
          v-model="state.doctorsCertificateIssueTime"
          name="doctorsCertificateIssueTime"
          is-link
          readonly
          label-class="label-bold"
          label="签发日期"
          placeholder="请选择签发日期"
          @click="show.showDoctorsCertificateIssueTime = true"
        />
        <van-popup v-model:show="show.showDoctorsCertificateIssueTime" position="bottom">
          <van-datetime-picker
            type="date"
            title="签发日期"
            :min-date="minDate"
            v-model="currentDate"
            :formatter="dateformatter"
            @confirm="handlePickerConfirm($event, 'doctorsCertificateIssueTime')"
            @cancel="show.showDoctorsCertificateIssueTime = false"
          />
        </van-popup>

        <div class="box-title">
          <div class="title label-bold">上传手写签名</div>
          <div class="tag">白色纸张，深色水笔，正楷书写，用于处方</div>
        </div>
        <div class="box-upload box-upload-hidden">
          <div class="box-upload-container">
            <van-uploader v-model="autographPhotoList" image-fit="contain" class="uploader-img" :max-count="1">
              <div class="phpto-box">
                <img
                  class="img"
                  :style="{
                    display: autographPhotoList.length ? 'none' : 'block',
                  }"
                  src="@/assets/ico-cer-photo.png"
                />
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="box-title">
          <div class="title label-bold">上传免冠照片</div>
          <div class="tag">纯色背景，穿白大褂，正面免冠，半身照片</div>
        </div>
        <div class="box-upload box-upload-hidden">
          <div class="box-upload-container">
            <van-uploader v-model="headPortraitList" image-fit="contain" class="uploader-img" :max-count="1">
              <div class="phpto-box">
                <img
                  class="img"
                  :style="{
                    display: headPortraitList.length ? 'none' : 'block',
                  }"
                  src="@/assets/headPortrait.png"
                />
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <div class="tip">
        <div>提示：</div>
        <div>以上信息仅用于身份核验，我们将为您严格保密，请放心填写。材料如有变动请及时更新，平台需要重新核验。</div>
      </div>
      <van-sticky position="bottom">
        <div class="button van-safe-area-bottom">
          <van-button
            class="button-submit linear-blue"
            round
            block
            type="primary"
            native-type="submit"
            :loading="isLoading"
            v-if="rzState != 0"
          >
            {{ rzState == 2 ? '补充更新' : '提交申请' }}
          </van-button>
        </div>
      </van-sticky>
    </van-form>

    <SearchDrawer
      ref="searchDrawerRef"
      v-model:hospitalName="state.hospitalName"
      v-model:hospitalId="state.hospitalId"
      :list="hospitalList"
      :keyValue="{ hospitalName: 'hospitalName', hospitalId: 'id' }"
      title="hospitalName"
      @searchClick="searchClick"
    ></SearchDrawer>
  </div>
</template>

<script>
import { doctorApply, getDoctorApplyBaseH5Dto } from '@/api/member';
import { getHospitalList, getDepartmentList } from '@/api/hospital';
import { uploadFile } from '@/api/upload';
import { isName, isBlank, isIdCard } from '@/utils/validate.js';
import { formatter, dateFormat } from '@/utils/formatter.js';
import { doctorApplyData } from '@/utils/data.js';
import { getStorage, setStorage, removeStorage } from '@/utils/storage.js';
import { reactive, ref, onMounted, watch } from 'vue';
import {
  Toast,
  Form,
  Field,
  CellGroup,
  Button,
  Popup,
  Picker,
  DatetimePicker,
  Uploader,
  Area,
  Image,
  Sticky,
} from 'vant';
import SearchDrawer from '@/components/SearchDrawer';
import { areaList } from '@vant/area-data';
import axios from 'axios';

export default {
  name: 'doctorApply2',
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Uploader.name]: Uploader,
    [Area.name]: Area,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    SearchDrawer,
  },
  props: ['rzState'],
  setup() {
    const idPhotoFList = ref([]);
    const idPhotoZList = ref([]);
    const autographPhotoList = ref([]);
    const headPortraitList = ref([]);
    const gradeCertificatePhotoList = ref([]);
    const certificateOfPracticePhotoList = ref([]); // 执业资格证书
    const doctorsCertificatePhotoList = ref([]); // 医生证书照片
    const departmentList = ref([]);
    const hospitalList = ref([]);
    const area = areaList;
    const titleColums = ['主任医师', '副主任医师', '主治医师', '住院医师'];
    const dateformatter = formatter;
    const isLoading = ref(false);

    const currentDate = ref(new Date()); // 默认日期选择器时间
    const show = reactive({
      showRegionPicker: false,
      showCalendar: false,
      showDepartmentPicker: false,
      showGradeCertificateIssueTime: false,
      showCertificateOfPracticePhotoIssueTime: false,
      showDoctorsCertificateIssueTime: false,
      showTitlePicker: false,
    });

    const state = ref(doctorApplyData);
    const initData = function (data) {
      if (!data) return;
      idPhotoZList.value = data.idPhotoZ
        ? [
            {
              url: data.idPhotoZ,
            },
          ]
        : [];
      idPhotoFList.value = data.idPhotoF
        ? [
            {
              url: data.idPhotoF,
            },
          ]
        : '';
      gradeCertificatePhotoList.value = data.gradeCertificatePhoto
        ? data.gradeCertificatePhoto.split(',').map((v) => {
            return {
              url: v,
            };
          })
        : [];
      doctorsCertificatePhotoList.value = data.doctorsCertificatePhoto
        ? data.doctorsCertificatePhoto.split(',').map((v) => {
            return {
              url: v,
            };
          })
        : [];
      certificateOfPracticePhotoList.value = data.certificateOfPracticePhoto
        ? data.certificateOfPracticePhoto.split(',').map((v) => {
            return {
              url: v,
            };
          })
        : [];

      autographPhotoList.value = data.autographPhoto
        ? [
            {
              url: data.autographPhoto,
            },
          ]
        : [];
      headPortraitList.value = data.headPortrait
        ? [
            {
              url: data.headPortrait,
            },
          ]
        : [];
      ({ ...state.value } = { ...data });
    };

    (async function () {
      if (getStorage('doctorApply')) {
        initData(JSON.parse(getStorage('doctorApply')));
      } else {
        const { code, data } = await getDoctorApplyBaseH5Dto();
        if (code == 200) {
          initData(data);
        }
      }
    })();

    // 监听state变化进行前端本地存储
    watch(state, (newProps) => {
      setStorage(JSON.stringify(newProps), 'doctorApply');
    });

    onMounted(async () => {
      const { data: department } = await getDepartmentList({
        keyWord: '',
      });
      departmentList.value = department;
    });

    const searchClick = async function (value) {
      const {
        data: { data: hospital },
      } = await getHospitalList({ keyWord: value, page: 1, limit: 100 });
      hospitalList.value = hospital;
    };

    // 校验函数可以直接返回一段错误提示
    const validatorName = (val) => isName(val);
    const validatorBlank = (val) => !isBlank(val);
    const validatorIdCard = (val) => isIdCard(val);

    // 确认picker点击
    const handlePickerConfirm = (data, type) => {
      if (type == 'department') {
        state.value.departmentId = data.id;
        state.value.departmentName = data.title;
        show.showDepartmentPicker = false;
      }
      if (type == 'title') {
        state.value.title = data;
        show.showTitlePicker = false;
      }
      if (type == 'area') {
        state.value.region = `${data[0].name},${data[1].name},${data[2].name}`;
        show.showRegionPicker = false;
      }
      if (type == 'startWorkTime') {
        const date = new Date(data);
        state.value.startWorkTime = dateFormat('YYYY-mm-dd', date);
        show.showCalendar = false;
      }
      if (type == 'gradeCertificateIssueTime') {
        const date = new Date(data);
        state.value.gradeCertificateIssueTime = dateFormat('YYYY-mm-dd', date);
        show.showGradeCertificateIssueTime = false;
      }

      if (type == 'certificateOfPracticePhotoIssueTime') {
        const date = new Date(data);
        state.value.certificateOfPracticePhotoIssueTime = dateFormat('YYYY-mm-dd', date);
        show.showCertificateOfPracticePhotoIssueTime = false;
      }
      if (type == 'doctorsCertificateIssueTime') {
        const date = new Date(data);
        state.value.doctorsCertificateIssueTime = dateFormat('YYYY-mm-dd', date);
        show.showDoctorsCertificateIssueTime = false;
      }
    };
    const handleSubmit = async () => {
      if (!idPhotoZList.value.length) {
        Toast('请上传身份证图片正面');
        return;
      }
      if (!idPhotoFList.value.length) {
        Toast('请上传身份证图片反面');
        return;
      }
      // if (!gradeCertificatePhotoList.value.length) {
      //   Toast('请上传专业技术资格证书');
      //   return;
      // }
      // if (!certificateOfPracticePhotoList.value.length) {
      //   Toast('请上传医师执业证书');
      //   return;
      // }
      // if (!doctorsCertificatePhotoList.value.length) {
      //   Toast('请上传医师资格证书');
      //   return;
      // }
      // if (!autographPhotoList.value.length) {
      //   Toast('请上传手写签名');
      //   return;
      // }
      // if (!headPortraitList.value.length) {
      //   Toast('请上传免冠照片');
      //   return;
      // }
      state.value.gradeCertificatePhoto = '';
      state.value.certificateOfPracticePhoto = '';
      state.value.doctorsCertificatePhoto = '';
      isLoading.value = true;
      axios
        .all([
          idPhotoZList.value[0].file
            ? uploadFile({
                file: idPhotoZList.value[0].file,
                upload: true,
              })
            : { data: { url: idPhotoZList.value[0].url } },
          idPhotoFList.value[0].file
            ? uploadFile({
                file: idPhotoFList.value[0].file,
                upload: true,
              })
            : { data: { url: idPhotoFList.value[0].url } },
          ...(!gradeCertificatePhotoList.value.length
            ? []
            : gradeCertificatePhotoList.value.map((v) => {
                if (v.url) return { data: { url: v.url } };
                return uploadFile({ file: v.file, upload: true });
              })),
          ...(!certificateOfPracticePhotoList.value.length
            ? []
            : certificateOfPracticePhotoList.value.map((v) => {
                if (v.url) return { data: { url: v.url } };
                return uploadFile({ file: v.file, upload: true });
              })),
          ...(!doctorsCertificatePhotoList.value.length
            ? []
            : doctorsCertificatePhotoList.value.map((v) => {
                if (v.url) return { data: { url: v.url } };
                return uploadFile({ file: v.file, upload: true });
              })),
          !autographPhotoList.value.length
            ? null
            : autographPhotoList.value[0].file
            ? uploadFile({
                file: autographPhotoList.value[0].file,
                upload: true,
              })
            : { data: { url: autographPhotoList.value[0].url } },
          !headPortraitList.value.length
            ? null
            : headPortraitList.value[0].file
            ? uploadFile({ file: headPortraitList.value[0].file, upload: true })
            : { data: { url: headPortraitList.value[0].url } },
        ])
        .then((res) => {
          state.value.idPhotoZ = res[0].data.url;
          state.value.idPhotoF = res[1].data.url;
          const certificate = 2 + gradeCertificatePhotoList.value.length;
          res.slice(2, certificate).map((v) => {
            if (state.value.gradeCertificatePhoto) {
              state.value.gradeCertificatePhoto = `${state.value.gradeCertificatePhoto},${v.data.url}`;
            } else {
              state.value.gradeCertificatePhoto = v.data.url;
            }
          });

          const gradecertificate = certificate + certificateOfPracticePhotoList.value.length;
          res.slice(certificate, gradecertificate).map((v) => {
            if (state.value.certificateOfPracticePhoto) {
              state.value.certificateOfPracticePhoto = `${state.value.certificateOfPracticePhoto},${v.data.url}`;
            } else {
              state.value.certificateOfPracticePhoto = v.data.url;
            }
          });

          const doctorsCertificate = gradecertificate + doctorsCertificatePhotoList.value.length;
          res.slice(gradecertificate, doctorsCertificate).map((v) => {
            if (state.value.doctorsCertificatePhoto) {
              state.value.doctorsCertificatePhoto = `${state.value.doctorsCertificatePhoto},${v.data.url}`;
            } else {
              state.value.doctorsCertificatePhoto = v.data.url;
            }
          });

          state.value.autographPhoto =
            res.slice(doctorsCertificate, doctorsCertificate + 1)[0] &&
            res.slice(doctorsCertificate, doctorsCertificate + 1)[0].data.url;
          state.value.headPortrait =
            res.slice(doctorsCertificate + 1, doctorsCertificate + 2)[0] &&
            res.slice(doctorsCertificate + 1, doctorsCertificate + 2)[0].data.url;
          handleUpload();
        });
    };

    const handleUpload = async () => {
      const { code: code1 } = await doctorApply({ ...state.value });
      if (code1 == 200) {
        Toast.success('上传成功，我们将在3个工作日内完成审核，请耐心等待');
      }
      isLoading.value = false;
      // 清空填写内容本地缓存
      removeStorage('doctorApply');
      const { code, data } = await getDoctorApplyBaseH5Dto();
      if (code == 200) {
        initData(data);
      }
    };

    // 医院搜索框实例
    const searchDrawerRef = ref(null);
    // 打开搜索医院
    const openSearchDrawer = () => {
      searchDrawerRef.value.openDrawer();
    };

    return {
      state,
      show,
      idPhotoFList,
      idPhotoZList,
      autographPhotoList,
      headPortraitList,
      hospitalList,
      currentDate,
      area,
      titleColums,
      dateformatter,
      isLoading,
      departmentList,
      gradeCertificatePhotoList,
      certificateOfPracticePhotoList,
      doctorsCertificatePhotoList,
      minDate: new Date(1900, 1, 1),
      validatorName,
      validatorBlank,
      validatorIdCard,
      handleSubmit,
      handlePickerConfirm,
      searchClick,
      openSearchDrawer,
      searchDrawerRef,
    };
  },
};
</script>
<style lang="scss">
.button-submit {
  width: 100%;
}

.uploader-img .van-uploader__preview {
  background: #fff;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  margin: 0;
  border: 1px dashed #a6aeb3;
  box-sizing: border-box;
}
.uploader-img .van-uploader__upload-icon {
  font-size: 40px;
}
.img-many .van-uploader__preview {
  margin-right: 10px;
  margin-bottom: 10px;
}
.uploader-img .van-image {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.container {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  .tip {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
    color: #a6aeb3;
    font-size: $font12;
    margin-top: 30px;
    padding-bottom: 45px;
    line-height: 18px;
  }
  .button {
    padding-bottom: 5px;
  }
}
.page-title {
  padding-left: 20px;
  padding-bottom: 25px;
  padding-top: 17px;
  .title {
    font-size: $font18;
    font-weight: 600;
    color: #5e7dff;
  }
  .tag {
    color: #a6aeb3;
    font-size: $font12;
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  .box-upload-hidden {
    height: 90px;
    display: flex;
    .box-upload-container {
      height: 90px;
    }
  }
}
</style>
