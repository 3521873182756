import request from '@/utils/request';


/**
 * @description 绑定手机号
 * @param {phone, code} params
 * @returns
 */
export function bindPhone(data) {
  return request({
    url: '/member/bindPhone',
    method: 'post',
    data,
  });
}
/**
 * @description 发送验证码
 * @param {phone} params
 * @returns
 */
export function sendBindSms(data) {
  return request({
    url: '/member/sendBindSms',
    method: 'post',
    data,
  });
}
/**
 * @description 换绑手机-给当前账号手机发送短信
 * @returns
 */
export function sendIdentitySms(data) {
  return request({
    url: '/member/sendIdentitySms',
    method: 'post',
    data,
  });
}
/**
 * @description 换绑手机-校验给当前账号手机短信码
 * @param code
 * @returns
 */
export function checkIdentityCode(data) {
  return request({
    url: '/member/checkIdentityCode',
    method: 'post',
    data,
  });
}

/**
 * @description setUserInfo
 * @param {phone} params
 * @returns
 */
export function setUserInfo(data) {
  return request({
    url: '/member/updateUserInfo',
    method: 'post',
    data,
  });
}

/**
 * @description 获取医生个人信息完善用户信息使用
 * @returns
 */
export function getDoctorInfo(params) {
  return request({
    url: '/member/getDoctorInfo',
    method: 'get',
    params,
  });
}

/**
 * @description 获取二维码
 * @returns
 */
export function getQrCode(params) {
  return request({
    url: '/member/getQrCode',
    method: 'get',
    params,
  });
}

/**
 * @description
 * @param {name,hospitalId,departmentId,
 * region,title,startWorkTime,idPhotoZ,idPhotoF,
 * gradeCertificatePhoto,gradeCertificateNumber,
 * gradeCertificateIssueTime} params
 * @returns
 */
export function doctorApply(data) {
  return request({
    url: '/member/doctorApply',
    method: 'post',
    data,
  });
}

/**
 * @description 获取医生认证信息
 */
export function getDoctorApplyBaseH5Dto(params) {
  return request({
    url: '/member/getDoctorApplyBaseH5Dto',
    method: 'get',
    params,
  });
}


/**
 * @description 我的患者或我的医生列表
 * @returns
 */
export function myPatientList(params) {
  return request({
    url: '/member/myPatientList',
    method: 'get',
    params,
  });
}
export function myDoctorList(params) {
  return request({
    url: '/member/myDoctorList',
    method: 'get',
    params,
  });
}
export function getPatientDetail(params) {
  return request({
    url: '/member/getPatientDetail',
    method: 'get',
    params,
  });
}

/**
 * @description 获取关注医生列表
 * @returns
 */
export function getFollowDoctorList(params) {
  return request({
    url: '/member/getFollowDoctorList',
    method: 'get',
    params,
  });
}


/**
 * @description 用户钱包历史流水
 * @returns
 */
export function getMemberTransactionLog(params) {
  return request({
    url: '/member/getMemberTransactionLog',
    method: 'get',
    params,
  });
}

/**
 * @description 获取用户钱包
 * @returns
 */
export function getWallet(params) {
  return request({
    url: '/member/getWallet',
    method: 'get',
    params,
  });
}
/**
 * @description 获取用户钱包
 * @returns
 */
export function getWalletDetail(params) {
  return request({
    url: '/member/getTransactionLog',
    method: 'get',
    params,
  });
}
/**
 * @description 提现
 * @param {money}
 * @returns
 */
export function addCash(data) {
  return request({
    url: '/member/addCash',
    method: 'post',
    data,
  });
}

