import request from '@/utils/request';


/**
* @description  获取搜索列表筛选项
* @param {bigClassId: 大类编号 1 医生 2医院} params
* @returns
*/
export function getScreenList(params) {
  return request({
    url: '/screen/getScreenList',
    method: 'get',
    params,
  });
}

export function getHospitalList(params) {
  return request({
    url: '/hospital/list',
    method: 'get',
    params,
  });
}


export function getHospitalModel(params) {
  return request({
    url: '/hospital/getModel',
    method: 'get',
    params,
  });
}


export function getDepartmentList(params) {
  return request({
    url: '/hospital/department/list',
    method: 'get',
    params,
  });
}
